<template>
  <div class="layout-wrapper">
    <BaseHeader></BaseHeader>
    <div class="main-wrapper">
      <div
        :class="{
          'left-collapse-wrapper': ifCollapse,
          'left-expand-wrapper': !ifCollapse,
        }"
        v-if="!ifHideSidebar"
      >
        <BaseSidebar
          :active="activeModule"
          @on-collapse="onCollapse"
          @select="handleSelectModule"
        ></BaseSidebar>
      </div>

      <div
        :class="{
          'right-wrapper': true,
          'right-wrapper-without-footer': ifHideFooter,
        }"
      >
        <div class="right-wrapper-header" v-if="!ifHidePageHeader">
          <div class="breadcrumb-nav-wrapper">
            <div class="breadcrumb">
              <BaseBreadcrumb :breadcrumbList="breadcrumbList"></BaseBreadcrumb>
              <div class="pageTitle">
                <el-tooltip
                  class="box-item"
                  effect="light"
                  placement="top-start"
                  v-if="
                    ifShowSpiderStatus &&
                    spiderInfo.isNeedUpdate &&
                    spiderInfo.spiderName
                  "
                >
                  <template #content>
                    该爬虫内容已变更，您可选
                    <br />
                    择
                    <span class="upload-button" @click="handleClickResetSpider">
                      撤销更新
                    </span>
                    或者
                    <span
                      class="upload-button"
                      @click="handleClickPublishSpider"
                    >
                      上线更新
                    </span>
                  </template>
                  <span style="color: red">
                    {{ pageTitle }}
                  </span>
                </el-tooltip>
                <span v-else>{{ pageTitle }}</span>
                <span
                  class="pageStatus"
                  v-if="
                    ifShowSpiderStatus &&
                    pageTitle !== '' &&
                    spiderInfo.spiderStatus !== null &&
                    spiderInfo.spiderName
                  "
                >
                  <!-- <SpiderStatusTag
                    :status="spiderInfo.spiderStatus"
                  ></SpiderStatusTag> -->
                  <SpiderStatusButton
                    v-if="spiderInfo.spiderStatus === SPIDER_STATUS.DRAFT"
                    :status="spiderInfo.spiderStatus"
                    @enable="handelClickEnableSpider"
                    class="draft-tag"
                  />
                  <!-- <i
                    class="iconfont icon-kaishi"
                    v-if="spiderInfo.spiderStatus === SPIDER_STATUS.DRAFT"
                    @click="handelClickEnableSpider"
                  /> -->
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          :class="{
            'page-wrapper': true,
            'page-wrapper-without-footer': ifHideFooter,
            'hide-padding': ifHidePadding,
            'hide-margin': ifHideMargin,
            'hide-background': ifHideBackground,
          }"
        >
          <router-view #="{ Component }">
            <!-- 将渲染的子组件动态绑定到 component 组件中 -->
            <component
              ref="routerViewRef"
              :key="$route.fullPath"
              :is="Component"
              @setBreadcrumbList="setBreadcrumbList"
              @setPageTitle="setPageTitle"
              @setSpiderInfo="setSpiderInfo"
            />
          </router-view>
        </div>
        <div class="footer-wrapper" v-if="!ifHideFooter">
          <BaseFooter></BaseFooter>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, reactive, ref, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MODULE } from "../../constant/router/module-name";
import BaseHeader from "./BaseHeader.vue";
import BaseSidebar from "./BaseSidebar.vue";
import BaseFooter from "./BaseFooter.vue";
import BaseBreadcrumb from "./BaseBreadcrumb.vue";
import { SPIDER_STATUS } from "@/constant/spider_status.js";
import SpiderStatusTag from "@/components/base/SpiderStatusTag.vue";
import { useUserInfo } from "@/utils/useUserInfo";
import { httpPost } from "@/api/httpService";
import SpiderStatusButton from "@/components/base/SpiderStatusButton.vue";
import VueCookies from "vue-cookies";

const route = useRoute();
const router = useRouter();

const routerViewRef = ref(null);
// 页面切换时自动滚动到顶部
watch(
  route,
  (route, oldRoute) => {
    nextTick(() => {
      document.getElementsByClassName("right-wrapper")[0].scrollTop = 0;
    });
  },
  {
    immediate: true,
    deep: true,
  }
);

//控制左侧菜单是否展开
const ifCollapse = ref(false);
const onCollapse = (_ifCollapse) => {
  ifCollapse.value = _ifCollapse;
};

// 控制左侧菜单是否隐藏
const ifHideSidebar = ref(false);
watch(
  route,
  (route, oldRoute) => {
    ifHideSidebar.value = route.meta.ifHideSidebar;
  },
  { immediate: true, deep: true }
);

// 控制左侧高亮
const activeModule = ref(null);
watch(
  route,
  (route, oldRoute) => {
    console.log("route", route);
    activeModule.value = route.meta.module;
  },
  { immediate: true, deep: true }
);

// 选择菜单项后
const handleSelectModule = (index) => {
  // console.log("select", index);
  switch (index) {
    case MODULE.HOME:
      router.push({ name: "HomeIndex" });
      break;
    case MODULE.PROJECT:
      router.push({ name: "ProjectIndex" });
      break;
    case MODULE.TASK_RECORD:
      router.push({ name: "TaskRecordIndex" });
      break;
    case MODULE.SCHEDULE_CENTER:
      router.push({ name: "ScheduleCenterIndex" });
      break;
    case MODULE.SPIDER_TEMPLATE:
      router.push({ name: "SpiderTemplateIndex" });
      break;
    case MODULE.RECYCLE_BIN:
      router.push({ name: "RecycleBin" });
      break;
    default:
      return;
  }
};
//面包屑
const breadcrumbList = ref([
  {
    title: "首页",
    to: {
      name: "HomeIndex",
      query: {},
    },
  },
]);
const setBreadcrumbList = (_breadcrumbList) => {
  console.log("🚀 ~ setBreadcrumbList ~ _breadcrumbList:", _breadcrumbList);
  breadcrumbList.value = _breadcrumbList;
};
//标题
const pageTitle = ref("");
const setPageTitle = (_pageTitle) => {
  pageTitle.value = _pageTitle;
};
//爬虫信息
const spiderInfo = ref({});
const setSpiderInfo = (_spiderInfo) => {
  spiderInfo.value = _spiderInfo;
};
//启用爬虫
const handelClickEnableSpider = () => {
  routerViewRef.value.handelClickEnableSpider();
};
//撤销爬虫
const handleClickResetSpider = () => {
  routerViewRef.value.handleClickResetSpider();
};
//上线爬虫
const handleClickPublishSpider = () => {
  routerViewRef.value.handleClickPublishSpider();
};

const ifHidePadding = ref(false); // 是否隐藏内部页面的padding
const ifHideMargin = ref(false); // 是否隐藏内部页面的margin
const ifHideBackground = ref(false); // 是否隐藏内部页面的背景色
const ifHidePageHeader = ref(false); // 是否隐藏内部页面头部
const ifShowSpiderStatus = ref(false); // 是否显示爬虫状态
watch(
  route,
  (route, oldRoute) => {
    ifHidePadding.value = route.meta.ifHidePadding;
    ifHideMargin.value = route.meta.ifHideMargin;
    ifHideBackground.value = route.meta.ifHideBackground;
    ifHidePageHeader.value = route.meta.ifHidePageHeader;
    ifShowSpiderStatus.value = route.meta.ifShowSpiderStatus;
  },
  { immediate: true, deep: true }
);

// 页脚
const ifHideFooter = ref(true);
watch(
  route,
  (route, oldRoute) => {
    ifHideFooter.value = route.meta.ifHideFooter;
  },
  { immediate: true, deep: true }
);

// 调用permission接口，
const getUserPermission = async () => {
  const { userId } = useUserInfo();
  // console.log(
  //   "🚀 ~ getUserPermission ~ userId:",
  //   userId,
  //   window.sessionStorage.getItem("ifLoginSPIDER_" + userId)
  // );
  let ifLogin = window.sessionStorage.getItem("ifLoginSPIDER_" + userId);
  if (ifLogin === "true" || !userId) {
    // console.log("return", ifLogin, !userId);
    return;
  }

  let res = await httpPost("/user/v1/getUserPermission", null);
  // console.log("🚀 ~ getUserPermission ~ res:", res);
  if (res?.code == 0) {
    window.sessionStorage.setItem("ifLoginSPIDER_" + userId, true);
    let userInfo = VueCookies.get("userInfo");
    userInfo.spiderPermission = res.data;
    userInfo.ifLoginSpider = true;
    VueCookies.set("userInfo", JSON.stringify(userInfo));
  }
};
onMounted(() => {
  setTimeout(() => {
    getUserPermission();
  }, 0);
});
</script>

<style lang="scss" scoped>
.layout-wrapper {
  .main-wrapper {
    height: $main-height;
    max-height: $main-height;
    display: flex;

    .left-wrapper {
      height: 100%;
      background: $main-background;
    }
    .left-collapse-wrapper {
      @extend .left-wrapper;
      width: $sidebar-collapse-width;
    }
    .left-expand-wrapper {
      @extend .left-wrapper;
      width: $sidebar-expand-width;
    }
    .right-wrapper {
      background: $main-background;

      height: 100%;
      flex: 1;
      overflow: auto;
      .right-wrapper-header {
        height: $right-wrapper-header-height;
        background: white;
      }

      .breadcrumb-nav-wrapper {
        width: 100%;
        height: $main-breadcrumb-nav-height;
        padding: 0 20px;
        .breadcrumb {
          height: 100%;
          padding: 20px 0;
          text-align: left;
          // align-items: center;
          .el-breadcrumb__inner.is-link {
            color: rgba(108, 108, 108, 1);
            font-size: 14px;
          }
          .pageTitle {
            margin-top: 15px;
            color: rgba(16, 16, 16, 1);
            font-size: 18px;
            font-family: SourceHanSansSC-bold;
            font-weight: 600;
            .pageStatus {
              font-weight: 500;
              display: inline-block;
              vertical-align: middle;
              margin-left: 20px;
              position: relative;
              top: -2px;
              .iconfont {
                display: inline-block;
                vertical-align: middle;
                margin-left: 5px;
                color: $theme-color;
                cursor: pointer;
              }
            }
          }
        }
      }
      .page-wrapper {
        position: relative;
        padding: 20px;
        min-height: $main-page-height;
        background: $main-page-background;
        // min-height: 100%;

        margin: {
          top: $main-page-wrapper-padding;
          left: $main-page-wrapper-padding;
          right: $main-page-wrapper-padding;
        }
      }
      .page-wrapper-without-footer {
        min-height: $main-page-height-without-footer;
      }
      .hide-padding {
        padding: 0px;
      }
      .hide-margin {
        margin: 0px;
      }
      .hide-background {
        background: inherit;
      }
      .footer-wrapper {
        height: $main-footer-height;
      }
    }
    .right-wrapper-without-footer {
      // padding: 10px 20px 20px;
    }
  }
}
.upload-button {
  cursor: pointer;
  color: $theme-color;
  font-size: 12px;
}
</style>
